import { useLoginMutation } from 'api/auth.api';
import { useToast } from 'common/otto-ui/toast';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { useAppDispatch } from 'configuration/redux/store';
import { UserChannelEnum, UserTypeEnum } from 'enums';
import { AuthSplitLayout } from 'layouts';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { updateCalendarConnectedStatus } from 'slices/calendar';
import { captureException, hasSubscriptionExpired } from 'utils';
import { LoginFormPartial } from 'views/login/partials';

export const LoginView = () => {
  const router = useRouter();
  const { toast } = useToast();
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    /**
     * Check for any router params on page load
     */
    if (router.query.account_deleted === 'true') {
      toast({
        title: 'Account Deleted',
        message: 'Your account was successfully deleted.',
        variant: 'success',
      });
    }
  }, [router]);

  const onSubmit = async (values: { email: string; password: string; }) => {
    await login(values)
      .unwrap()
      .then(async ({ user, calendarConnected }) => {
        let redirectTo: string = ROUTE_ENUM.HOME;
        if (user.type === UserTypeEnum.CUSTOMER) {
          if (user.channel === UserChannelEnum.BENEFITS && hasSubscriptionExpired(user.benefit)) {
            redirectTo = ROUTE_ENUM.SUB_EXPIRED;
          }
        } else if (user.type === UserTypeEnum.COACH) {
          dispatch(updateCalendarConnectedStatus(calendarConnected));
        }
        const deepLink = router.query?.link as string;
        if (deepLink) {
          try {
            redirectTo = new URL(deepLink, window.location.origin).toString();
          } catch (err) {
            captureException(err);
          }
        }
        await router.replace(redirectTo);
      })
      .catch((err) => {
        captureException(err);
        toast({
          message: err?.message,
          variant: 'error',
        });
      });
  };

  return (
    <AuthSplitLayout title="Login" image="static/images/bg-leaf.webp">
      <LoginFormPartial onSubmit={onSubmit} isLoading={isLoading} />
    </AuthSplitLayout>
  );
};
