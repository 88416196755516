import { usePathname } from "next/navigation";
import { useMemo } from "react";

export const useActiveRoute = () => {
  const pathname = usePathname();
  const route = useMemo(() => {
    if (!pathname) {
      return null;
    }
    if (pathname.length === 1) {
      return pathname;
    }
    const a = pathname.split('/');
    if (a.length > 1) {
      return `/${a[1]}`;
    }
    return null;
  }, [pathname]);
  return [route, pathname];
};
