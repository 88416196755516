import { HeadingSmall, ParagraphMedium, ParagraphSmall, useTheme } from "@otto-finance/ui";
import { BreadcrumbItem } from "common/otto-ui/breadcrumbs";
import { Column, Row } from "common/otto-ui/ui";
import { PageBreadcrumbs } from "components/page-breadcrumbs";
import { ReactNode } from "react";

interface PageLayoutProps {
  title?: string;
  subTitle?: string;
  contentPadding?: string;
  breadcrumbs?: BreadcrumbItem[];
  endEnhancer?: ReactNode;
  children: ReactNode;
}

export const PageLayout = ({ title, subTitle, breadcrumbs, contentPadding, endEnhancer, children }: PageLayoutProps) => {
  const [css, theme] = useTheme();
  return (
    <div className={css({
      backgroundColor: theme.colors.white,
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'max-content 1fr'
    })}>
      {breadcrumbs ? <PageBreadcrumbs items={breadcrumbs} /> : <div />}
      <Column
        gap={theme.sizing.scale800}
        className={css({ padding: contentPadding || theme.sizing.scale800, gridTemplateRows: title ? 'max-content 1fr' : '1fr' })}>
        <Row align="between">
          {title && (
            <Column>
              <HeadingSmall>{title}</HeadingSmall>
              <ParagraphSmall className={css({ maxWidth: '700px', color: theme.colors.primary500 })}>{subTitle}</ParagraphSmall>
            </Column>
          )}
          {endEnhancer && <div>{endEnhancer}</div>}
        </Row>
        <div>{children}</div>
      </Column>
    </div>
  );
};
