import Link from 'next/link';
import styles from './sidebar-navigation.module.scss';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { Logo, LogoIcon } from 'components/logo';
import { memo, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { SidebarItem } from './sidebar-item';
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon, ComboboxIcon, FileIcon, FolderIcon, LogoutIcon, MessageCircleIcon, SettingsIcon, UserIcon, UsersIcon } from '@otto-finance/ui';
import { BiritshPoundCircleIcon, GridIcon,  } from 'common/otto-ui/icons';
import { useActiveRoute } from 'common/roq-hooks/use-active-route';
import { useFeature, useLoginHook } from 'views/login/hooks';
import { useDisclosure, useLogout } from 'common/roq-hooks';
import { Popover, PopoverContent, PopoverTrigger } from 'common/otto-ui/popover';
import { Image } from 'components';
import { useAppDispatch } from 'configuration/redux/store';
import { toggleSidebar, toggleSidebarCollapse, uiSettingsSelector, uiSidebarSelector } from 'slices/ui';
import { useSelector } from 'react-redux';
import { Notifications } from 'components/notifications';
import { customerMainRoutes, CustomerRouteType, customerToolRoutes, customerWealthRoutes } from './customer-routes';
import acls from 'shared/acls';
import { UserTypeEnum } from 'enums';
import { FeatureFlagEnum } from 'configuration/data';
import { AvatarDefaultIcon } from 'common/otto-ui/avatar';
import { MessagesBell } from 'components/messages-bell';

export const SidebarNavigation = memo(({ fixedWidth }: { fixedWidth?: boolean; }) => {
  const el = useRef(null);
  const { isCustomer, isCoach } = useLoginHook();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (fixedWidth) {
      return;
    }
    if (el.current) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentRect.width < 100) {
            dispatch(toggleSidebar(false));
          } else {
            dispatch(toggleSidebar(true));
          }
        }
      });
      observer.observe(el.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <div
      key="SidebarNavigation"
      ref={el}
      className={styles.root}
      data-fixedwidth={fixedWidth}>
      <div>
        <SidebarHeader />
        {isCustomer ? <ClientMenu /> : isCoach ? <CoachMenu /> : <AdminMenu />}
      </div>
      <SidebarFooter />
    </div>
  );
});

const SidebarHeader = () => {
  const { isAdmin } = useLoginHook();
  return (
    <div className={styles.top}>
      <Link href={ROUTE_ENUM.HOME} prefetch={false} title="Home">
        <span><Logo width={60} /></span>
        <span><LogoIcon size={31} /></span>
      </Link>
      {!isAdmin && (
        <>
          <Notifications />
          <MessagesBell />
        </>
      )}
    </div>
  );
};

const GroupMenu = ({ id, title, icon, route, children }: { id: string; title: string; route: string; icon: ReactNode; children: ReactNode; }) => {
  const { isOpenId, isWide } = useSelector(uiSidebarSelector);
  const dispatch = useAppDispatch();
  const { isOpen, onToggle } = useDisclosure();
  const [router] = useActiveRoute();
  const onToggleCollapse = useCallback(() => {
    dispatch(toggleSidebarCollapse({ id: id }));
  }, []);

  if (isWide) {
    return (
      <div
        key={`GroupMenu_${id}`}
        className={styles.groupMenu}
        data-open={isOpenId === id}>
        <SidebarItem
          onClick={onToggleCollapse}
          title={title}
          icon={icon}
          href={route}
          endEnhancer={isOpenId === id ? <ChevronUpIcon size="16" /> : <ChevronDownIcon size="16" />}
          selected={router === route} />
        <div className={styles.groupSubMenu}>{children}</div>
      </div>
    );
  }

  return (
    <Popover open={isOpen} onOpenChange={onToggle}>
      <PopoverTrigger asChild>
        <button className={styles.subMenuTrigger} data-selected={router === route}>
          {icon}
        </button>
      </PopoverTrigger>
      <PopoverContent autoFocus={false} side='right' align="start">
        <div className={styles.subMenuList} data-menu="true">
          {children}
        </div>
      </PopoverContent>
    </Popover>
  );

};

const SidebarSectionTitle = ({ title }: { title: string; }) => {
  return (
    <div className={styles.sectionTitle}>{title}</div>
  );
};

const filterWithPermissions = (arr: CustomerRouteType[], hasAccess: (feature: FeatureFlagEnum) => boolean) => {
  return arr
    .filter(f => {
      return f.acls ? acls[f.id]?.[f.acls] ? acls[f.id][f.acls].includes(UserTypeEnum.CUSTOMER) : false : true;
    })
    .filter(f => {
      return f.feature ? hasAccess(f.feature) : true;
    });
};

const ClientMenu = () => {
  const [router, pathName] = useActiveRoute();
  const { coachTitle } = useSelector(uiSettingsSelector);
  const { hasAccess } = useFeature();
  const mainRoutes = useMemo(() => filterWithPermissions(customerMainRoutes(coachTitle), hasAccess), []);
  const toolRoutes = useMemo(() => filterWithPermissions(customerToolRoutes, hasAccess), []);
  const wealthRoutes = useMemo(() => filterWithPermissions(customerWealthRoutes, hasAccess), []);

  return (
    <>
      <div key="ClientMenu1" className={styles.listContainer}>
        {mainRoutes.map(({ icon: Icon, ...r }) => (
          <SidebarItem
            key={`ClientMenu1Itemn_${r.id}`}
            title={r.title}
            icon={<Icon />}
            href={r.href}
            selected={router === r.href}
          />
        ))}
        <GroupMenu title="Wealth" icon={<BiritshPoundCircleIcon />} id={'WealthMenu'} route={ROUTE_ENUM.WEALTH}>
          {wealthRoutes.map(r => (
            <SidebarItem
              key={`WealthMenu_${r.id}`}
              title={r.title}
              href={r.href}
              selected={pathName === r.href}
              subItem
            />
          ))}
        </GroupMenu>
      </div>
      <SidebarSectionTitle title="Tools" />
      <div key="ClientMenu3" className={styles.listContainer}>
        {toolRoutes.map(({ icon: Icon, ...r }) => (
          <SidebarItem
            key={`ClientMenu3_${r.id}`}
            title={r.title}
            icon={<Icon />}
            href={r.href}
            selected={router === r.href}
          />
        ))}
      </div>
    </>
  );
};

const CoachMenu = () => {
  const [router] = useActiveRoute();
  return (
    <div key="CoachMenu" className={styles.listContainer}>
      <SidebarItem title="Dashboard" icon={<GridIcon />} href={ROUTE_ENUM.HOME} selected={router === ROUTE_ENUM.HOME} />
      <SidebarItem title="Clients" icon={<UsersIcon />} href={ROUTE_ENUM.USERS} selected={router === ROUTE_ENUM.USERS} />
      <SidebarItem title="Reports" icon={<FileIcon />} href={ROUTE_ENUM.REPORTS} selected={router === ROUTE_ENUM.REPORTS} />
      <SidebarItem title="Meetings" icon={<CalendarIcon />} href={ROUTE_ENUM.SESSION_LIST} selected={router === ROUTE_ENUM.SESSION_LIST} />
      <SidebarItem title="Documents" icon={<FolderIcon />} href={ROUTE_ENUM.DOCUMENTS} selected={router === ROUTE_ENUM.DOCUMENTS} />
      <SidebarItem title="Messages" icon={<MessageCircleIcon />} href={ROUTE_ENUM.MESSAGES} selected={router === ROUTE_ENUM.MESSAGES} />
    </div>
  );
};

const AdminMenu = () => {
  const [router] = useActiveRoute();
  return (
    <div key="AdminMenu" className={styles.listContainer}>
      <SidebarItem title="Dashboard" icon={<GridIcon />} href={ROUTE_ENUM.HOME} selected={router === ROUTE_ENUM.HOME} />
      <SidebarItem title="Users" icon={<UsersIcon />} href={ROUTE_ENUM.USERS} selected={router === ROUTE_ENUM.USERS} />
      <SidebarItem title="Coaches" icon={<UsersIcon />} href={ROUTE_ENUM.COACHES} selected={router === ROUTE_ENUM.COACHES} />
      <SidebarItem title="Admins" icon={<FileIcon />} href={ROUTE_ENUM.ADMINS} selected={router === ROUTE_ENUM.ADMINS} />
    </div>
  );
};

const SidebarFooter = () => {
  return (
    <div key="SidebarFooter" className={styles.listContainer}>
      <SidebarItem title="Settings" icon={<SettingsIcon />} href={ROUTE_ENUM.SETTINGS} />
      <UserProfile />
    </div>
  );
};

const UserProfile = () => {
  const { userData } = useLoginHook();
  const { handleLogout } = useLogout();
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Popover open={isOpen} onOpenChange={onToggle}>
      <PopoverTrigger asChild>
        <button className={styles.userProfile}>
          {userData?.avatar ? (
            <Image
            src={userData?.avatar ?? ''}
            fallbackComponent={<span><AvatarDefaultIcon /></span>}
            alt={userData.fullName}
            circle
            radius="6px"
            width={34}
            height={34}
          />
          ) : (
            <span><AvatarDefaultIcon /></span>
          )}
          <div>
            <span>{userData.fullName}</span>
            <small>{userData.email}</small>
          </div>
          <ComboboxIcon size="16" />
        </button>
      </PopoverTrigger>
      <PopoverContent autoFocus={false}>
        <div className={styles.subMenuList}>
          <SidebarItem title="My profile" icon={<UserIcon />} href={ROUTE_ENUM.SETTINGS_PROFILE} />
          <SidebarItem title="Sign out" icon={<LogoutIcon />} href={ROUTE_ENUM.HOME} onClick={handleLogout} />
        </div>
      </PopoverContent>
    </Popover>
  );
};
