'use client';

import { ChevronRightIcon, LabelMedium, LabelSmall, useTheme } from "@otto-finance/ui";
import { Row } from "../ui";

export type BreadcrumbItem = {
  label: string;
  id: string;
  href?: string;
};

type BreadcrumbsProps = {
  items: BreadcrumbItem[];
  onSelect?: (item: BreadcrumbItem) => void;
};

export const Breadcrumbs = ({ items, onSelect }: BreadcrumbsProps) => {
  const [css, theme] = useTheme();
  return (
    <Row>
      {items.map((item, i) => (
        <Row key={`breadcrumb_${item.id}_${i}`} crossalign="center">
          {i < items.length - 1 ? (
            <>
              <LabelSmall
                {...(onSelect && {
                  onClick: () => onSelect(item)
                })}
                title={item.label}
                className={css({
                  // textDecoration: 'underline',
                  color: theme.colors.primary500,
                  cursor: 'pointer',
                  ':hover': {
                    textDecoration: 'underline',
                    color: theme.colors.primary
                  }
                })}
              >{item.label}</LabelSmall>
              <ChevronRightIcon size="14px" color={theme.colors.primary500} />
            </>
          ) : (
            <LabelSmall key={`breadcrumb_${item.id}`} color={theme.colors.primary400}>{item.label}</LabelSmall>
          )}
        </Row>
      ))}
    </Row>
  );
};