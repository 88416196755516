/* eslint-disable react/display-name */
import { Backdrop } from 'common/otto-ui';
import { useAuth } from 'common/roq-hooks/use-auth';
import { MainHeader } from 'components/main-header';
import { ROUTE_ENUM } from 'configuration/data/routers';
import React from 'react';
import { WithToken } from './with-token.hoc';

type WithAuthenticationOptions = {
  /**
   * Used for public routes to login via token.
   */
  token?: boolean;
  /**
   * Is public
   */
  optional?: boolean;
};

export const withAuthentication = function (Component: React.FunctionComponent, options?: WithAuthenticationOptions): React.FC {
  if (!options?.token && options?.optional) {
    return Component;
  }
  return ({ ...props }) => {
    if (options?.token) {
      return <WithToken><Component {...props} /></WithToken>;
    }

    const { accessToken } = useAuth({ redirectTo: ROUTE_ENUM.LOGIN });
    if (!accessToken) {
      return (
        <>
          <MainHeader />
          <Backdrop fullScreen open={true} />
        </>
      );
    }
    return <Component {...props} />;
  };
};

// session-room?id=1&token=2
// session-room?id=1