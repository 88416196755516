import { NotificationInterface, NotificationType } from 'common/interfaces';

import { caching } from 'configuration/data';
import { DeleteNotificationDocument, NotificationsDocument, ReadAllNotificationsDocument, ReadNotificationDocument, UnreadNotificationsCountDocument } from 'configuration/graphql/documents/notifications.document';

import { API_TAGS, baseApi } from './base.api';
import { DatesModel, OrderInterface } from 'common/interfaces/common.interface';


enum NotificationChannelEnum {
  APP = 'APP',
  EMAIL = 'EMAIL',
}

interface ListNotificationsArgsInterface {
  userId?: string;
  id?: string;
  order?: OrderInterface[];
  limit?: number;
  offset?: number;
}

interface NotificationsResponseInterface {
  totalCount?: number;
  data: {
    id: string;
    type: string;
    readAt?: Date;
    dates: DatesModel;
    payload?: {
      channel: NotificationChannelEnum;
      data: any;
    }[];
  }[];
}

export const notificationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    notificationsUnreadCount: builder.query<number, null>({
      query: () => ({
        document: UnreadNotificationsCountDocument
      }),
      transformResponse: (data: { unreadNotificationsCount; }) => data.unreadNotificationsCount,
      providesTags: [API_TAGS.NOTIFICATIONS_UNREAD],
    }),
    notifications: builder.query<NotificationInterface[], ListNotificationsArgsInterface>({
      query: (args) => ({
        variables: args,
        document: NotificationsDocument
      }),
      transformResponse: ({ listNotifications }: { listNotifications: NotificationsResponseInterface; }) => {
        const { data } = listNotifications;
        return data.reduce((acc, curr) => {
          acc.push({
            id: curr.id,
            type: curr.type,
            readAt: curr.readAt,
            data: curr.payload.find(f => f.channel === NotificationChannelEnum.APP)?.data,
            dates: curr.dates
          });
          return acc;
        }, []).filter(f => f.data.title);
      },
      keepUnusedDataFor: 0,//caching.twoMinutes,
      providesTags: [API_TAGS.NOTIFICATIONS],
    }),
    readAllNotifications: builder.mutation<boolean, null>({
      query: () => ({
        document: ReadAllNotificationsDocument
      }),
      invalidatesTags: [API_TAGS.NOTIFICATIONS_UNREAD]
    }),
    readNotification: builder.mutation<boolean, { ids: string[]; }>({
      query: (variables) => ({
        variables,
        document: ReadNotificationDocument
      }),
      invalidatesTags: [API_TAGS.NOTIFICATIONS_UNREAD]
    }),
    deleteNotification: builder.mutation<boolean, { id: string; }>({
      query: (variables) => ({
        variables,
        document: DeleteNotificationDocument
      })
    }),
  }),
});

export const {
  useNotificationsUnreadCountQuery,
  useNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
  useLazyNotificationsQuery,
  useDeleteNotificationMutation
} = notificationsApi;
