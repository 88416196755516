import { usePageChange } from 'common/roq-hooks';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';
import { useLoginHook } from 'views/login/hooks';

export const PublicPage = ({ children }: { children: ReactNode; }) => {
  const { isLogged } = useLoginHook();
  const router = useRouter();
  const onRouteChangeStart = useCallback(p => {
   if (isLogged && p === ROUTE_ENUM.LOGIN) {
    router.push(ROUTE_ENUM.HOME);
   }
  }, [isLogged]);
  usePageChange('beforeHistoryChange', onRouteChangeStart);
  return children;
};
