/* eslint-disable @next/next/no-img-element */
import { OttoIcon } from "common/otto-ui/icons";
import { useSelector } from "react-redux";
import { uiSettingsSelector } from "slices/ui";

export const Logo = ({ contrast, width }: { contrast?: boolean; width?: number; }): JSX.Element => {
  const { site } = useSelector(uiSettingsSelector);
  if (site?.logoUrl) {
    return <img alt={site?.title} src={`${contrast ? site?.logoUrlContrast : site?.logoUrl}`} width={site?.logoWidth ? site?.logoWidth : width ? `${width}px` : 'auto'} />;
  }
  return <OttoIcon width={width} color={contrast ? 'white' : 'currentColor'} />;
};

export const LogoIcon = ({ size }: { size: number; }) => {
  const { site } = useSelector(uiSettingsSelector);
  return <img alt={site?.title} src={`${site?.faviconUrl ?? '/static/assets/otto-accent.png'}`} width={size} height={size} />;
};
