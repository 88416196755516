import { FileTextIcon, FolderIcon, IconProps, PlayCircleIcon, TargetIcon, UserIcon } from "@otto-finance/ui";
import { GridIcon, MapPinIcon } from "common/otto-ui/icons";
import { FeatureFlagEnum } from "configuration/data";
import { ROUTE_ENUM } from "configuration/data/routers";
import { ACL } from "shared/data-info";
import { capitalizeFirstLetter } from "utils";

export type CustomerRouteType = {
  id: string;
  title: string;
  icon?: any;
  href: ROUTE_ENUM;
  acls?: ACL;
  feature?: FeatureFlagEnum;
};

export const customerMainRoutes = (coachPersona: string): CustomerRouteType[] => {
  return [
    {
      id: "dashboard",
      title: "Dashboard",
      icon: GridIcon,
      href: ROUTE_ENUM.HOME
    },
    {
      id: "coach",
      title: coachPersona,
      icon: UserIcon,
      href: ROUTE_ENUM.COACH,
      acls: ACL.READ,
      feature: FeatureFlagEnum.COACH
    },
    {
      id: "documents",
      title: "Documents",
      icon: FolderIcon,
      href: ROUTE_ENUM.DOCUMENTS,
      acls: ACL.READ,
      feature: FeatureFlagEnum.DOCUMENTS
    },
    {
      id: "articles",
      title: "Articles",
      icon: FileTextIcon,
      href: ROUTE_ENUM.LEARN
    }
  ];
};

export const customerToolRoutes: CustomerRouteType[] = [
  {
    id: "actions",
    title: "Actions",
    icon: TargetIcon,
    href: ROUTE_ENUM.ACTIONS,
    feature: FeatureFlagEnum.COACH
  },
  {
    id: "roadmap",
    title: "Roadmap",
    icon: MapPinIcon,
    href: ROUTE_ENUM.ROADMAP,
    acls: ACL.READ,
    feature: FeatureFlagEnum.ROADMAP
  },
  {
    id: "ottomate",
    title: "Rules",
    icon: PlayCircleIcon,
    href: ROUTE_ENUM.OTTOMATE,
    acls: ACL.READ,
    feature: FeatureFlagEnum.WEALTH
  }
];

export const customerWealthRoutes: CustomerRouteType[] = [
  {
    id: "investments",
    title: "Investments",
    href: ROUTE_ENUM.WEALTH_INVESTMENTS
  },
  {
    id: "investment_analysis",
    title: "Investment analysis",
    href: ROUTE_ENUM.WEALTH_INVESTMENT_ANALYSIS,
    acls: ACL.READ,
    feature: FeatureFlagEnum.WEALTH_ANALYSIS
  },
  {
    id: "cashflow",
    title: "Cashflow",
    href: ROUTE_ENUM.WEALTH_CASHFLOW,
    acls: ACL.READ
  },
  {
    id: "assets",
    title: "Assets",
    href: ROUTE_ENUM.WEALTH_ASSETS,
    acls: ACL.READ
  },
  {
    id: "transactions",
    title: "Transactions",
    href: ROUTE_ENUM.TRANSACTIONS,
    acls: ACL.READ
  }
];
