import { useMobile } from 'common/roq-hooks/use-mobile';
import { SidebarNavigation } from 'components/sidebar-navigation';
import dynamic from 'next/dynamic';

const NavbarMobile = dynamic(() => import('components/navbar/navbar-mobile').then((c) => c.NavbarMobile), { ssr: false });

export const Navbar = () => {
  const isMobile = useMobile();
  if (isMobile) {
    return <NavbarMobile />;
  }
  return <SidebarNavigation />;
};
