import { LabelSmall, useTheme, Button, H1, HeadingLarge } from '@otto-finance/ui';
import Image from "next/image";
import Link from 'next/link';
import { ReactNode } from 'react';

export const NoAccounts: React.FC<{
  subTitle: string;
  title: string;
  description?: string;
  image: string;
  button?: {
    label: string;
    url: string;
  };
  children?: ReactNode;
}> = ({ subTitle, title, description, image, button, children }) => {
  const [css, theme] = useTheme();
  return (
    <div className={css({
      display: 'grid',
      alignItems: 'start',
      padding: theme.sizing.scale800,
      [theme.mediaQuery.medium]: {
        height: '100%',
        maxWidth: `${theme.breakpoints.large}px`,
        justifyContent: 'space-around',
        alignItems: 'center',
        justifyItems: 'center',
        gridTemplateColumns: '1fr 60%',
        margin: '0 auto',
      },
    })}>
      <div className={css({ display: 'grid', maxWidth: '500px' })}>
        <div className={css({ display: 'grid', gap: theme.sizing.scale800, justifyItems: 'start', alignContent: 'center' })}>
          <div>
            <LabelSmall>{subTitle}</LabelSmall>
            <h2 className={css({
              ...theme.typography.HeadingSmall,
              [theme.mediaQuery.medium]: {
                ...theme.typography.HeadingLarge
              },
            })}>{title}</h2>
          </div>
          {description && <HeadingLarge width={'80%'}>{description}</HeadingLarge>}
          {button && (<Link href={button.url} prefetch={false}><Button>{button.label}</Button></Link>)}
          {children}
        </div>
      </div>
      <div className={css({
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '300px'
      })}>
        <Image
          fill
          style={{ objectFit: 'contain' }}
          alt={title}
          src={image}
        />
      </div>
    </div>
  );
};
