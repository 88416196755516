import { useMemo } from "react";
import { useSelector } from "react-redux";
import { chatConversationSelector, chatUsersSelector } from "slices/chat";
import { userCoachSelector } from "slices/user-coach";
import { useLoginHook } from "views/login/hooks";

export const useConversationEndUser = (conversationId: string) => {
  const { isCoach } = useLoginHook();
  const { coach } = useSelector(userCoachSelector);
  const conversation = useSelector(chatConversationSelector(conversationId));
  const users = useSelector(chatUsersSelector);
  const endUser = useMemo(() => {
    if (isCoach) {
      return users.entities[conversation.ownerId];
    }
    return coach;
  }, [users, conversationId]);
  return endUser;
}
