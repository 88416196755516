import { useEffect } from "react";
import { chatConversationSelector } from "slices/chat";
import { useSelector } from "react-redux";
import { useFetchConversation } from "./use-fetch-conversation.hook";

export const useConversation = (conversationId: string) => {
  const conversation = useSelector(chatConversationSelector(conversationId));
  const { getConversation, isLoading, isError } = useFetchConversation();

  useEffect(() => {
    if (!conversation) {
      getConversation(conversationId, true);
    }
  }, [conversation]);

  return { conversation, isLoading, isError };
};
