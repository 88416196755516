import { Backdrop } from "common/otto-ui";
import { NoAccounts } from "components/no-accounts";
import { ROUTE_ENUM } from "configuration/data/routers";
import { useAppDispatch } from "configuration/redux/store";
import { WithRoleAuth } from "hocs";
import { ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { assetsSelector } from "slices/assets";
import { setPageName } from "slices/ui/ui.slice";

import { MainLayout } from "./main";

export const WealthLayout: React.FC<{ title: string; fixedWidth?: boolean; pageName?: string; children?: ReactNode; }> = ({ title, fixedWidth, pageName, children }) => {
  const { loadingAccountsFinished, totalAssets } = useSelector(assetsSelector);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (pageName) {
      dispatch(setPageName(pageName));
    }
  }, []);
  return (
    <MainLayout title={title} pageTitle={title}>
      <WithRoleAuth permissionKey="wealth.read">
        {!loadingAccountsFinished ? (
          <Backdrop open={true} fullScreen />
        ) : (
          totalAssets === 0 ? (
            <NoAccounts
              subTitle='LIFE GOALS'
              title='Get full visibility of your cashflow.'
              image='/static/images/cashflow-preview.png'
              button={{ label: 'Add Assets', url: ROUTE_ENUM.WEALTH_ADD_ASSET }}
            />
          ) : (
            children
          )
        )}
      </WithRoleAuth>
    </MainLayout>
  );
};
