import { useTheme } from '@otto-finance/ui';
import clsx from 'clsx';
import { Logo } from 'components/logo';
import { MainHeader } from 'components/main-header';
import { ReactNode } from 'react';

export interface PatternInterface {
  children?: ReactNode;
  isBackShow?: boolean;
  hideSmallPattern?: boolean;
  hideSmallestPattern?: boolean;
  pageTitle: string;
  childMargin?: string;
}

export const PatternLayout = ({
  children,
  pageTitle,
  childMargin,
} :PatternInterface) => {
  const [css, theme] = useTheme();
  const classes = {
    wrapper: clsx('w-screen h-screen overflow-y-auto dark:bg-background-dark-screen'),
    childWrapper: clsx(
      'flex flex-col items-center justify-center w-full pb-8',
      childMargin ?? 'lg:mt-20 sm:mt-6 md:mt-24',
    ),
    logoWrapper: clsx('w-full flex justify-center'),
  };
  return (
    <div className={classes.wrapper}>
      <MainHeader title={pageTitle} />
      <div className="flex w-2/5 sm:w-11/12 m-auto items-center pt-5">
        <div className={classes.logoWrapper}>
        <Logo width={80} />
        </div>
      </div>
      <div className={classes.childWrapper}>{children}</div>
    </div>
  );
};
