import { FeatureFlagEnum } from 'configuration/data';
import { ReactNode } from 'react';
import { useFeature } from 'views/login/hooks';

interface WithAccessProps {
  children?: ReactNode;
  feature: FeatureFlagEnum;
  failComponent?: ReactNode;
}

export const WithAccess = ({ feature, children, failComponent }: WithAccessProps) => {
  const { hasAccess } = useFeature();
  if (hasAccess(feature)) {
    return children;
  }
  return failComponent || null;
};
