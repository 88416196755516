import { useLazyConversationQuery } from "api/conversation.api";
import { useConversationMembers } from "./use-conversation-members.hook";
import { useCallback, useState } from "react";
import { useAppDispatch } from "configuration/redux/store";
import { addConversation } from "slices/chat";

export const useFetchConversation = () => {
  const dispatch = useAppDispatch();
  const [fetchConversation, { isError }] = useLazyConversationQuery();
  const [isLoading, setLoading] = useState(false);
  const { getMembers } = useConversationMembers();
  const getConversation = useCallback(async (id: string, preSelected = false) => {
    setLoading(true);
    await fetchConversation({ id })
      .unwrap()
      .then(async (res) => {
        if (res) {
          await getMembers([res]);
          await dispatch(addConversation({ ...res, isPreselected: preSelected }));
          setLoading(false);
        }
      }).catch(() => {
        setLoading(false);
      });
  }, []);
  return { getConversation, isLoading, isError };
};
