import { useLoginGuestMutation } from 'api/auth.api';
import { Backdrop } from 'common/otto-ui';
import { MainHeader } from 'components/main-header';
import { ROUTE_ENUM } from 'configuration/data/routers';
import omit from 'lodash/omit';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import NotFoundPage from 'pages/404';
import React, { ReactNode, useEffect, useState } from 'react';
import { captureException } from 'utils';
import { useLoginHook } from 'views/login/hooks';

export const WithToken = ({ children }: { children: ReactNode; }) => {
  const { isLogged } = useLoginHook();
  const params = useSearchParams();
  const [isWaiting, setWaiting] = useState(true);
  const router = useRouter();
  const [login, { isLoading, isUninitialized, isError }] = useLoginGuestMutation();
  useEffect(() => {
    if (isLogged) {
      return;
    }
    if (!router.isReady) {
      return;
    }
    const token = params.get('token');
    if (token) {
      setWaiting(false);
      login({ token: token })
        .unwrap()
        .then(() => {
          router.replace({ query: omit(router.query, 'token') });
        })
        .catch(err => {
          captureException(err, { token });
          router.push(ROUTE_ENUM.LOGIN);
        });
    } else {
      setWaiting(false);
      router.push(ROUTE_ENUM.LOGIN);
    }
  }, [router]);
  if (isLogged) {
    return children;
  }
  if (isLoading || isUninitialized || isWaiting) {
    return (
      <>
        <MainHeader />
        <Backdrop fullScreen open={true} />
      </>
    );
  }
  if (isError) {
    return <NotFoundPage />;
  }
  return children;
};
