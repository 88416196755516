import Head from 'next/head';
import { useSelector } from 'react-redux';
import { uiSettingsSelector } from 'slices/ui';

interface MainHeaderPropsInterface {
  title?: string;
}

export const MainHeader = ({ title }: MainHeaderPropsInterface) => {
  const { site } = useSelector(uiSettingsSelector);
  return (
    <Head>
      <title>{site?.title ?? 'Otto'} - {title}</title>
      <link rel="shortcut icon" type="image/png" href={site?.faviconUrl ?? '/static/assets/otto-accent.png'} sizes="16x16 32x32 48x48" />
      <meta name="theme-color" content="#ffffff" />
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    </Head>
  );
};
