import { ROUTE_ENUM } from "configuration/data/routers";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { useLoginHook } from "views/login/hooks";

interface NoAuthLayoutProps {
  children: ReactNode;
}

export const NoAuthLayout: React.FC<NoAuthLayoutProps> = ({ children }) => {
  const { isLogged } = useLoginHook();
  const [loaded, setLoaded] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (isLogged) {
      void router.push(ROUTE_ENUM.HOME);
    } else {
      setLoaded(true);
    }
  }, []);
  return (<>{loaded ? children : null}</>);
};
