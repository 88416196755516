'use client'

import { PublicPage } from 'hocs/public-page.hoc';
import { LoginView } from 'views/login';

export async function getStaticProps() {
  return {
    props: {}
  };
}

const LoginPage = () => <PublicPage><LoginView /></PublicPage>;

export default LoginPage;
