import { styled } from "@otto-finance/ui";

export const PageHeaderNav = styled<'div', {}>(
  'div',
  ({ $theme }) => ({
    minHeight: '70px',
    borderBottomColor: $theme.colors.primary200,
    borderBottomWidth: '1px',
    display: 'grid',
    alignItems: 'center'
  }),
);
