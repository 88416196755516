import { MainHeader } from "components/main-header";
import { ReactNode } from "react";

export const EmptyLayout = ({ title, children }: { title: string; children: ReactNode; }) => {
  return (
    <>
      <MainHeader title={title} />
      {children}
    </>
  );
};
