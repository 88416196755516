import { useTheme } from "@otto-finance/ui";
import NextLink from "next/link";
import { AnchorHTMLAttributes, ReactNode } from "react";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  children: ReactNode;
  prefetch?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  block?: boolean;
  border?: boolean;
};

export const Link: React.FC<LinkProps> = ({
  children,
  prefetch = false,
  startIcon,
  endIcon,
  block,
  border = true,
  ...rest
}) => {
  const [css, theme] = useTheme();
  return (
    <NextLink 
    {...rest} 
    className={css({ 
      ...(border && { borderBottom: 'solid 1px currentColor' }),
      display: block ? 'flex' :'inline-flex',
      fontWeight: theme.weights.medium,
      gap: theme.sizing.scale400,
      ...(block && {
        justifyContent: 'space-between'
      }),
      ':hover': {
        opacity: 0.7
      }
      })} 
      prefetch={prefetch}>
      {startIcon && startIcon}
      <span>{children}</span>
      {endIcon && endIcon}
    </NextLink>
  );
};
