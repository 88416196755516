import { useChannel } from "common/roq-hooks/use-pusher";
import { useCallback, useEffect } from "react";
import { ChannelEvent, DATE_FORMAT } from "enums";
import { useAppDispatch } from "configuration/redux/store";
import { addChatMessage, addChatMessageRemote, chatActiveConversationSelector, chatConversationsSelector } from "slices/chat";
import { useLoginHook } from "views/login/hooks";
import { useSelector } from "react-redux";
import { useFetchConversation } from "./use-fetch-conversation.hook";
import { randId } from "utils";
import { PusherChannels } from "utils/pusher-channels";
import format from "date-fns/format";

export const useChatEvents = () => {
  const { userData, isCoach } = useLoginHook();
  const channel = useChannel(PusherChannels.globalChannel(userData.id));
  const dispatch = useAppDispatch();
  const conversations = useSelector(chatConversationsSelector);
  const { getConversation } = useFetchConversation();
  const conversationId = useSelector(chatActiveConversationSelector);

  useEffect(() => {
    if (!channel) return;

    channel.bind(ChannelEvent.messageAdded, onMessageAddedEvent);
    return () => {
      if (channel) {
        channel.unbind(ChannelEvent.messageAdded, onMessageAddedEvent);
      }
    };
  }, [channel, conversations.ids, conversationId]);

  const onMessageAddedEvent = useCallback(async (payload: {
    authorId: string;
    message: string;
    conversationId: string;
  }) => {

    if (userData.id === payload?.authorId) return;

    if (!conversations.ids.includes(payload.conversationId)) {
      await getConversation(payload.conversationId);
    } else {
      if (!isCoach) return;

      if (conversationId !== payload.conversationId) {
        dispatch(addChatMessage({
          id: randId(),
          body: payload.message,
          authorId: payload.authorId,
          createdAt: new Date().toISOString(),
          date: format(new Date(), DATE_FORMAT.DEFAULT),
          conversationId: payload.conversationId,
          messageStatus: {
            data: []
          }
        }));
        dispatch(addChatMessageRemote(payload.conversationId));
      }
    }
  }, [conversations, conversationId]);

};
