import { FailComponent } from 'components/fail-component';
import get from 'lodash/get';
import LoginPage from 'pages/login';
import { ReactNode } from 'react';
import acls from 'shared/acls';
import { useLoginHook } from 'views/login/hooks';

interface WithRoleAuthProps {
  children?: ReactNode;
  permissionKey: string;
  failComponent?: ReactNode;
  isGuest?: boolean;
}

/**
 * Page wrapper to handle if the user has the correct roles and authentication
 * for the required page
 */
export const WithRoleAuth = ({ permissionKey, children, failComponent, isGuest }: WithRoleAuthProps) => {
  const { userData: { type }, guest } = useLoginHook();
  const permission = get(acls, permissionKey);
  if (permission?.includes(type)) {
    if (!guest) {
      return children;
    }
    if (!isGuest) {
      return <LoginPage />;
    }
    if (permission?.includes('guest')) {
      return children;
    }
    return <LoginPage />;
  }
  return failComponent || <FailComponent />;
};
